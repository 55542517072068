*{
    box-sizing: border-box;

}
body{
    margin: 0;
    padding: 0;
}

.react-date-picker__wrapper {
    border-radius: 4px !important;
    border-color: rgba(0, 0, 0, 0.23) !important;
}
.react-calendar__viewContainer {
    background: white;
    border: 1px solid grey;
}

.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* .react-multi-carousel-list {
    position: unset !important;
  } */
  /* .react-multiple-carousel__arrow {
    background-color: transparent !important;
  } */
  .react-multiple-carousel__arrow::before {
    color: black !important;
  }
  /* .react-multiple-carousel__arrow--left {
    left: calc(6% + 1px) !important;
  }
  .react-multiple-carousel__arrow--right {
    right: calc(6% + 1px) !important;
  } */
  .react-multiple-carousel__arrow:hover {
    background: transparent;
  }
  .MuiSlider-markLabel {
    bottom: 34px !important;
    top: unset !important;
    color: #686868 !important;
  }
  .MuiSlider-markLabel {
    bottom: 34px !important;
    font-size: calc(.7em + 1vmin) !important;
  }
  .MuiSlider-marked {
    margin-bottom: 0 !important;
  }
  .flexcroll{
    scrollbar-face-color: #367CD2;
    scrollbar-shadow-color: #FFFFFF;
    scrollbar-highlight-color: #FFFFFF;
    scrollbar-3dlight-color: #FFFFFF;
    scrollbar-darkshadow-color: #FFFFFF;
    scrollbar-track-color: #FFFFFF;
    scrollbar-arrow-color: #FFFFFF;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

/* .Component-root-57 {
  color: #777777 !important;
}
.MuiRadio-root {
  color: red !important;
} */
.WithStyles\(ForwardRef\(Dialog\)\)-root-9 {
  background-color: transparent !important;
}
.react-multiple-carousel__arrow {
  z-index: 0 !important;
}

